<template>
    <div class="menu">
        <div class="product-box">
            <div class="product" @click="naviagatePage('https://www.mslmsxp.com')">
                <img src="./../assets/app/official_app_icon.png" @click="naviagatePage('https://www.mslmsxp.com')">
                <div class="product-name">秒数联盟数学派</div>
                <div class="product-information">
                    <span>2023</span>
                    <span>边缘骇客实验室出品</span>
                </div>
            </div>
            <div class="product" @click="naviagatePage('https://cat.mslmsxp.com')">
                <img src="./../assets/app/cat_app_icon.png" alt="">
                <div class="product-name">迷你英语单词猫</div>
                <div class="product-information">
                    <span>2023</span>
                    <span>边缘骇客实验室出品</span>
                </div>
            </div>
            <div class="product" @click="naviagatePage('https://word.willwaking.com')">
                <img src="./../assets/app/word_app_icon.png" alt="">
                <div class="product-name">醒语开源英语网</div>
                <div class="product-information">
                    <span>2024</span>
                    <span>热氧细胞工作室出品</span>
                </div>
            </div>
            <div class="product" @click="naviagatePage('https://cloud.mslmsxp.com')">
                <img src="./../assets/app/cloud_app_icon.png" alt="">
                <div class="product-name">亿题云资料宝库</div>
                <div class="product-information">
                    <span>2023</span>
                    <span>边缘骇客实验室出品</span>
                </div>
            </div>
            <div class="product" @click="naviagatePage('https://livehelper.willwaking.com')">
                <img src="./../assets/app/livehelper_app_icon.png" alt="">
                <div class="product-name">直播互动助手</div>
                <div class="product-information">
                    <span>2024</span>
                    <span>边缘骇客实验室出品</span>
                </div>
            </div>
            <div class="product" @click="naviagatePage('https://linenazi.willwaking.com')">
                <img src="./../assets/app/linenazi_app_icon.png" alt="">
                <div class="product-name">灵能纳茨综合服务网</div>
                <div class="product-information">
                    <span>2024</span>
                    <span>灵能纳茨工作室出品</span>
                </div>
            </div>
            <div class="product" @click="naviagatePage('https://blog.willwaking.com')">
                <img src="./../assets/app/edgehacker_app_icon.png" alt="">
                <div class="product-name">编程英语研究网</div>
                <div class="product-information">
                    <span>2024</span>
                    <span>边缘骇客实验室出品</span>
                </div>
            </div>
            <div class="product" @click="naviagatePage('https://planet.mslmsxp.com')">
                <img src="./../assets/app/planet_app_icon.png" alt="">
                <div class="product-name">数学派解题星球</div>
                <div class="product-information">
                    <span>2023</span>
                    <span>边缘骇客实验室出品</span>
                </div>
            </div>
            <div class="product" @click="naviagatePage('https://classmate.mslmsxp.com')">
                <img src="./../assets/app/classmate_app_icon.png" alt="">
                <div class="product-name">云学霸陪学网</div>
                <div class="product-information">
                    <span>2025</span>
                    <span>边缘骇客实验室出品</span>
                </div>
            </div>

            <div class="product" @click="naviagatePage('https://iding.willwaking.com')">
                <img src="./../assets/app/iding_app_icon.png" alt="">
                <div class="product-name">迪醒智能会话助手</div>
                <div class="product-information">
                    <span>2025</span>
                    <span>热氧细胞工作室出品</span>
                </div>
            </div>

        </div>
        <div class="bottom">
            <a href="https://linenazi.willwaking.com/">灵能纳茨综合服务网</a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        naviagatePage(url) {
            window.open(url, "_blank")
        }
    },
    mounted() {
        console.log("秒数联盟数学派")
    }
}
</script>
<style scoped>
.menu {
    width: 210px;
    display: flex;
    flex-direction: column;
    background-color: #151728;
    color: #FFFFFF;
    min-height: 600px;
}

.product-box {
    overflow-y: scroll;
    height: calc(100vh - 40px);
    min-height: 560px;
    border-bottom: 1px solid #24273B;
    box-sizing: border-box;
}


.product {
    width: 180px;
    margin: 15px;
    background-color: #24273B;
    border-radius: 5px;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    cursor: pointer;
    text-decoration: none;
    text-align: center;
}

.product:hover {
    background-color: #383C57;
}

.product-name {
    margin-top: 10px;
    color: #91AAE5;
}

.product-information {
    color: #4D516B;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.product-information span {
    font-size: 12px;
}

img {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    cursor: pointer;
}

.bottom {
    height: 40px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    background-color: #151728;
}

.bottom a {
    color: #4D516B;
}
</style>